'use client';

import {
  type BaseVariant,
  closeSnackbar,
  type EnqueueSnackbar,
  type OptionsObject,
  type OptionsWithExtraProps,
  type SnackbarKey,
  type SnackbarMessage,
  type TransitionHandlerProps,
  useSnackbar,
  type VariantType,
} from 'notistack';
import { useRef } from 'react';

interface UseSingleSnackbar {
  enqueueSnackbar: EnqueueSnackbar;
}

/*
 * For better UX we want to avoid 'preventDuplicate', since the user might not realize that
 * the snackbar occurred again. Instead, we want to close the previous snackbar and open a new one.
 * This hook is used to prevent multiple same snackbars from being displayed at the same time.
 * It will close the previous snackbar before opening a new one.
 */
export default function useSingleSnackbar(): UseSingleSnackbar {
  const { enqueueSnackbar } = useSnackbar();
  const snackBarRef = useRef<SnackbarKey | null>(null);

  function singleSnackbar<V extends VariantType>(
    options: OptionsWithExtraProps<V> & { message?: SnackbarMessage },
  ): SnackbarKey;
  function singleSnackbar<V extends VariantType>(
    message: SnackbarMessage,
    options?: OptionsWithExtraProps<V>,
  ): SnackbarKey {
    if (snackBarRef.current !== null) {
      closeSnackbar(snackBarRef.current);
    }

    const isOptionsWithMessage = (
      options: OptionsWithExtraProps<V> | SnackbarMessage,
    ): options is OptionsWithExtraProps<V> & { message: SnackbarMessage } => {
      return options !== null && typeof options === 'object' && Object.hasOwn(options, 'message');
    };

    const msg = isOptionsWithMessage(options) ? options.message : message;

    let variant: BaseVariant | undefined;
    let onExited: TransitionHandlerProps['onExited'] | undefined;
    let rest: Omit<OptionsObject<V>, 'variant' | 'onExited'> = {};

    if (options !== undefined && typeof options === 'object') {
      ({ variant, onExited, ...rest } = options);
    }

    // @TODO should be refactored if we want to use custom variants
    const isBaseVariant = (variant: string | undefined): variant is BaseVariant => {
      return (
        variant === 'default' ||
        variant === 'error' ||
        variant === 'info' ||
        variant === 'success' ||
        variant === 'warning'
      );
    };

    const snackbarOptions: OptionsObject<BaseVariant> = {
      variant: isBaseVariant(variant) ? variant : 'default',
      onExited: (node, key) => {
        if (snackBarRef.current === key) {
          snackBarRef.current = null;
        }
        if (onExited !== undefined && typeof onExited === 'function') {
          onExited(node, key);
        }
      },
      ...rest,
    };

    snackBarRef.current = enqueueSnackbar(msg, snackbarOptions);

    return snackBarRef.current;
  }

  return {
    enqueueSnackbar: singleSnackbar,
  };
}
